.rsw-wrapper {
    min-height: 400px !important;
}

/** Progress Bar */
$progress-height: 5px;
$duration: 3s;

@keyframes stripes {
    from { background-position: 1rem 0 }
    to { background-position: 0 0 }
}

@keyframes rainbow {
    0% { background-color: var(--blue) }
    20% { background-color: var(--blue) }
    40% { background-color: var(--blue) }
    60% { background-color: var(--blue) }
    80% { background-color: var(--blue) }
    100% { background-color: var(--blue) }
}

@keyframes grow {
    from { width: 0% }
    to { width: 100% }
}

.progress-wrapper {
    padding-top: 25%;
}

.progress-cont {
    height: $progress-height;
    overflow: hidden;
}

.progress-bar {
    height: $progress-height;
    width: 100%;

    .loaded & {
        animation: grow $duration ease,
        stripes 1s linear infinite,
        rainbow $duration ease infinite;
    }
}
