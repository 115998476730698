html {
  overflow-y: scroll;
}

.recap__wrapper {
  display: flex;
  background-color: #f2f2f2;
  height: 100%;
}

.sidebar {
  height: 100%;
  width: 14.72%;
  background: #f0f0f0;
  border-right: 1px solid rgba(23, 25, 31, 0.1);
  padding: 35px 16px;
  position: fixed;
  z-index:999;
  @media (max-width: 1500px) {
    width: 16.72%;
  }
  @media(max-width:1350px){
    width:212px;
    
  }
  .logo__flex {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 21.15px;
    img {
      width: 90%;
    }
  }
  .sidebar__menu {
    width: 90.56%;
    height: 86.66vh;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

//sidebar mobile

.top-level__sidebar {
  background: var(--black);
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  top: 0;
  padding: 20px 15px;
  .logo__mobile {
    @media (max-width: 850px) {
      width: 25%;
    }
    @media (max-width: 600px) {
      width: 40%;
    }
  }
  .hamburger {
    position: absolute;
    top: 20px;
    left: 15px;
    @media (max-width: 600px) {
      margin-right: 103px;
    }
  }
}

.sidebar__mobile-container {
  height: 100vh;
  background: var(--black);
  color: #fff;
  width: 212px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999999999999;
  padding: 12px 15px 28px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media(max-width:600px){
    width:227px;
    overflow-y:scroll;
  }
  .close-sidebar {
    width: 13.81px;
    height: 13.81px;
    position: absolute;
  }
}

//inner sidebar
.sidebar__list {
  @media (max-width: 850px) {
    margin-top: 80px;
  }
  a {
    text-decoration: none;
    .sidebar__item {
      padding: 14px 15px;
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 8px;
      transition: all 0.3s;
      background: transparent;
      cursor: pointer;
      @media(max-width:1350px){
        padding:10px 15px;
      }
      img {
        margin-right: 15px;
      }
      span {
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        color: #55595f;
      }
	  &:hover {
        background: #d0d0d0;
		span {
			color: #1e1f26ff;
		}
      }
    }
    .sidebar__highlight{
			span {
			 // background: rgba(255, 255, 255, 0.1);
				color: #1e1f26ff;
			}
		background: #d0d0d0;
	}
  }
}
.user-list__container {
  .credits-box {
    background: linear-gradient(
      116.82deg,
      #9242fd 0%,
      #7f6ff8 18.75%,
      #69a3f2 68.75%,
      #56c7d2 92.71%,
      #43eab2 100%
    );
    backdrop-filter: blur(22px);
    border-radius: 7px;
    padding: 21px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 18px;
    height: auto;
    .star__icon-box {
      width: 46px;
      height: 46px;
      background: #ffffff;
      border-radius: 14px;
      padding: 12px;
      margin-bottom: 20px;
    }
    span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #ffffff;
      &:focus-visible {
        outline: none;
        text-decoration: none;
        border: none;
      }
    }
    a {
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
      &:active {
        color: none;
      }
    }
  }
  .user-box {
    //background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    transition: all 0.3s;
    img {
      margin-right: 8px;
      height: 24px;
      width: 24px;
      object-fit: cover;
      border-radius: 50%;
    }
    span {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #55595f;
      word-break:break-all;
    }
	&:hover {
        background: #d0d0d0;
		span {
			color: #1e1f26ff;
		}
	}	
  }
  .hover-background {
    background: rgba(255, 255, 255, 0.1);
  }
  .logout__box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 21px;
    margin-bottom: 28px;
    padding: 10px 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s;
    img {
      margin-right: 17px;
    }
    span {
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #55595f;
    }
	&:hover {
        background: #d0d0d0;
		span {
			color: #1e1f26ff;
		}
	}
  }
}

@media (max-width: 850px) {
  .sidebar__desktop {
    display: none;
  }
}

.sidebar__mobile {
  z-index: 1;
  @media (min-width: 851px) {
    display: none;
  }
}

//Video

.video__wrapper {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .close__video {
    height: 25px;
    width: 25px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 13%;
    right: 11.83%;
    cursor: pointer;
    @media (max-width: 1700px) {
      right: 5.83%;
      top: 12%;
    }
    @media (max-width: 1350px) {
      right: 7.83%;
      top: 12%;
    }
    @media (max-width: 1025px) {
      top: 12%;
      right: 1.83%;
    }
    @media (max-width: 850px) {
      top: 25%;
    }
    @media (max-width: 600px) {
      top: 30%;
    }
  }
  video {
    margin-left: 16%;
    @media (max-width: 1400px) {
      width: 800px;
    }
    @media (max-width: 900px) {
      margin-left: 0;
      width: 90%;
    }
    @media (max-width: 600px) {
      height: 300px;
    }
  }
  .video__checkbox {
    width: 990px;
    display: flex;
    justify-content: center;
    margin-left: 16%;
    margin-top: 19px;
    @media (max-width: 1400px) {
      width: 800px;
    }
    @media (max-width: 900px) {
      margin-left: 0;
      width: 90%;
    }
    input {
      margin-right: 20px;
    }
    p {
      color: #fff;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 27px;
    }
  }
}
