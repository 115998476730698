/* Homepage */

.gpt__container {
  width: 79.16%;
  margin: 0 auto;
  z-index: 1;
}

/* navbar */

.navbar__flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 16px;
  .login__logo{
    height:100px;
    width:unset;
  }
}

.login {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #17191f;
  text-decoration: none;
}

.arrow {
  margin-left: 6px;
}

/* first section */

.gpt__first-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #9242fc;
  margin-bottom: 30px;
}

.title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  color: #17191f;
  /* width: 42%; */
  margin-bottom: 45px;
}

.title span {
  background: linear-gradient(
    90deg,
    rgba(146, 66, 252, 0.75) 0%,
    rgba(105, 163, 241, 1) 50%,
    rgba(66, 239, 176, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* form */

.form__wrapper {
  width: 48.68%;
}

.form__container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  background: linear-gradient(
    89.11deg,
    rgba(255, 255, 255, 0.97) 57.29%,
    rgba(255, 255, 255, 0.485) 100%
  );
  border: 1px solid #17191f;
  backdrop-filter: blur(5px);
  border-radius: 10px;
  position: relative;
  z-index: 0;
}
.mc-field-group {
  width: 100%;
}

.email {
  height: 57px;
  width: 100%;
  border: none;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  padding-left: 25px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #6e7999;
}

.email::placeholder {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: #6e7999;
}

.email:focus-visible {
  outline: none;
}

.button {
  height: 59px;
  width: 32.44%;
  background: #17191f;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  color: #ffffff;
  box-shadow: none;
  border: none;
  z-index: 1;
  position: absolute;
  right: -1px;
  cursor: pointer;
  transition: all 0.3s;
}

.button:hover {
  background: #9242fc;
}

/* second section */

.gpt__second-section {
  margin-top: 60px;
  margin-bottom: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* footer */
footer {
  border-top: 1px solid #e0e0e0;
  padding-top: 25px;
  padding-bottom: 25px;
}

.footer__flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer__text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #6e7999;
}

.footer__text a {
  color: #6e7999;
  text-decoration: none;
  transition: all 0.3s;
}

.footer__text a:hover {
  text-decoration: underline;
}

.footer__social {
  display: flex;
  align-items: center;
}

.twitter {
  margin-right: 20.94px;
}

/* background images */

.purple {
  position: absolute;
  left: 0;
  z-index: -1;
}

.blue {
  position: absolute;
  right: 0;
  z-index: -1;
}

.green {
  position: absolute;
  right: 0;
  top: 346px;
  z-index: -1;
}

/* Queries */
/* laptop */
@media (max-width: 1440px) {
  /* .title {
    width: 56%;
  } */
}
/* tablets */
@media (max-width: 1024px) {
  .gpt__container {
    width: 89.16%;
  }

  /* .title {
    width: 95%;
  } */
  .second-section__image {
    width: 80%;
  }
  .form__wrapper {
    width: 76.68%;
  }
}

@media (max-width: 900px) {
  /* .title {
    width: 95%;
  } */
}

@media (max-width: 850px) {
  /* .title {
    width: 87%;
  } */
}

@media (max-width: 768px) {
  /* .title {
    width: 88%;
  } */
}

/* mobile */
@media (max-width: 600px) {
  .gpt__container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
  .navbar__flex {
    margin-bottom: 60px;
    margin-top: 15px;
  }
  .logo {
    width: 152px;
    height: 36px;
    object-fit: cover;
  }

  .subtitle {
    line-height: 30px;
  }

  .title {
    font-size: 28px;
    line-height: 43px;
    /* width: 100%; */
  }
  .second-section__image {
    width: 100%;
  }
  .footer__flex {
    flex-direction: column;
    align-items: flex-start;
  }
  .footer__text {
    line-height: 20px;
    margin-bottom: 30px;
  }
  .form__wrapper {
    width: 100%;
  }
  .form__container {
    flex-wrap: wrap;
    border: none;
  }
  .email {
    flex-basis: 100%;
    background: linear-gradient(
      89.11deg,
      rgba(255, 255, 255, 0.97) 57.29%,
      rgba(255, 255, 255, 0.485) 100%
    );
    border: 1px solid #17191f;
    backdrop-filter: blur(5px);
    border-radius: 10px;
    margin-bottom: 11px;
  }
  .button {
    flex-basis: 100%;
    position: relative;
    height: 57px;
  }
  .purple,
  .blue,
  .green {
    width: 134px;
  }

  .green {
    top: 74%;
  }
}

/* THANKYOU PAGE */

.thankyou__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.thankyou__subtitle {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  text-transform: uppercase;
  color: #9242fc;
  margin-bottom: 30px;
}

.thankyou__title {
  font-style: normal;
  font-weight: 700;
  font-size: 54px;
  line-height: 70px;
  text-align: center;
  color: #17191f;
}

.thankyou__button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 57px;
  width: fit-content;
  margin-top: 45px;
  padding-left: 50.5px;
  padding-right: 50.5px;
  background: #17191f;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;
  text-decoration: none;
  transition: all 0.3s;
}

.thankyou__button:hover {
  background: #9242fc;
}

/* Queries */

@media (max-width: 600px) {
  .thankyou__title {
    font-size: 31px;
    line-height: 43px;
    width: 100%;
  }
}
