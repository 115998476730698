.credits__box {
  height: 220px;
  width: 100%;
  background: linear-gradient(
    116.82deg,
    #e2cdff 0%,
    #e0dcff 18.75%,
    #c0dbff 68.75%,
    #c3e5e9 92.71%,
    #cbf0e4 100%
  );
  backdrop-filter: blur(22px);
  border-radius: 7px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-left: 45px;
  padding-right: 45px;
  @media (max-width: 1024px) {
    padding-left: 35px;
    padding-right: 35px;
  }
  @media (max-width: 600px) {
    margin-top: 40px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .credits__text {
    display: flex;
    flex-direction: column;

    p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: var(--black);
      margin-bottom: 20px;
    }
    .credits {
      margin-bottom: 0;
    }
    span {
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: var(--black);
    }
  }
}

.credits__popup {
  padding: 60px 85px;
  background: #fff;
  border-radius: 16px;
  text-align: center;
  @media (max-width: 600px) {
    width: 100%;
  }
  img {
    margin-bottom: 45.87px;
  }
  h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: var(--black);
    margin-bottom: 20px;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6b7280;
    margin-bottom: 35px;
  }
  .credit__button {
    border-radius: 8px;
    background: var(--black);
    color: #fff;
    padding: 6px 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    box-shadow: none;
    border: none;
  }
}

//transaction history
.transaction__list-container {
  width: 59%;
  margin: 0 auto;
  padding-top: 60px;
  padding-bottom: 45px;
  @media (max-width: 1500px) {
    width: 84%;
  }
  @media (max-width: 1025px) {
    width: 90%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: var(--black);
    margin-bottom: 30px;
  }
  .transaction__row-wrapper {
    display:flex;
    align-items:center;
    border-bottom: 1px solid rgba(23, 25, 31, 0.1);
    .transaction__icon {
      border: 1px solid rgba(23, 25, 31, 0.1);
      backdrop-filter: blur(22px);
      border-radius: 7px;
      height: 40px;
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 25px;
    }
    .transaction__row {
      width:100%;
      padding-bottom: 21px;
      padding-top: 23px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position:relative;
      @media (max-width: 1200px) {
        flex-wrap: wrap;
        justify-content: flex-start;
      }
      .transaction__text {
        display: flex;
        align-items: center;
        @media (max-width: 1200px) {
          width:100%;
          flex-basis:100%;
          flex-direction: column;
          order:1;
          align-items:flex-start;
        }
        h5 {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: var(--black);
          @media (max-width: 900px) {
            margin-bottom: 4px;
          }
          @media (max-width: 600px) {
            width:80%;
          }
        }
      }
      .transaction__date {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: rgba(23, 25, 31, 0.4);
        margin-bottom: 0;
        @media (max-width: 1200px) {
          order: 2;
        }
      }
      .transaction__job {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: rgba(23, 25, 31, 0.4);
        margin-bottom: 0;
        @media (max-width: 1200px) {
          order: 3;
        }
      }
      .transaction__credits {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--black);
        @media (max-width: 1200px) {
          position:absolute;
          right:0;
        }
      }
    }
  }
}

.show-more__button {
  margin: 0 auto;
  margin-top: 60px;
  padding: 6px 16px;
  width: fit-content;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--black);
  cursor: pointer;
}

//sidebar
.credits__link {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  margin-top: 15px;
  @media (max-width: 1250px) {
    font-size: 12px;
  }
  @media (max-width: 1150px) {
    font-size: 10px;
  }
  @media (max-width: 1050px) {
    font-size: 9px;
  }
  @media (max-width: 900px) {
    font-size: 14px;
  }
}

//PRICING PLANS

.plans__wrapper {
  width: 90.017%;
  margin: 0 auto;
  margin-bottom: 120px;
  @media (max-width: 1200px) {
    width: 100%;
  }
}

.plans__container {
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 30px;
  @media (max-width: 1025px) {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 30px;
  }
  @media (max-width: 600px) {
    margin-top: 30px;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0px;
  }
  .pricing__card {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 30px 40px -13px rgba(18, 18, 18, 0.08);
    border-radius: 16px;
    padding: 20px;
    height: 398px;
    @media (max-width: 600px) {
      height: 333px;
    }
    h5 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: var(--black);
      margin-bottom: 20px;
    }
    ul {
      li {
        display: flex;
        align-items: flex-start;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: var(--black);
        margin-bottom: 14px;
        img {
          margin-right: 10px;
          margin-top: 3px;
        }
      }
    }
    .plan__details {
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
      h6 {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: var(--black);
        margin-bottom: 20px;
      }
      button {
        width: 100%;
      }
    }
    .popular__tag {
      position: absolute;
      top: 20px;
      right: 20px;
      background: #69a3f2;
      border-radius: 4px;
      padding: 4px;
      color: #fff;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.faq__container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 28px;
  row-gap: 45px;
  margin-top: 39px;
  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0;
  }
  .single__faq {
    h4 {
      margin-bottom: 19px;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: var(--black);
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: rgba(23, 25, 31, 0.4);
      margin-bottom: 0;
    }
  }
}
