.profile__container {
  width: 29.47%;
  @media(max-width:600px){
    width:100%;
    padding-right:15px;
    padding-bottom:100px;
  }
  .user__avatar{
    width:60px;
    height:60px;
    border-radius:50%;
    margin-bottom:20px;
    @media(max-width:600px){
      margin-top:50px;
    }
  }
  .user-data__form{
    margin-bottom:60px;
  }
  form {
    width: 100%;
    span{
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      color:#888888;
      margin-bottom:30px;
    }
    input {
      width: 100%;
    }
    input[type=file]{
      height:32px;
      background: #F9FAFB;
      padding-left:0;
      border-radius:7px;
      display:flex;
      flex-direction:flex-end;
      margin-bottom:14px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: rgba(23, 25, 31, 0.4);
      &::-webkit-file-upload-button {
        height:30px;
        background: #EFEFEF;
        border: 1px solid #767676;
        border-radius: 7px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color:var(--black);
      }
    }
    .submit__container{
      display:flex;
      align-items:center;
      justify-content: flex-end;
      button{
        margin-bottom:0;
        margin-left:20px;
      }
      span{
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color:var(--black);
        margin-bottom:0;
        cursor:pointer;
      }
    }
  }
}

.logout__button{
  position:absolute;
  top:28px;
  right:3%;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  height:40px;
  padding-right:16px;
  padding-left:16px;
  @media(max-width:600px){
    top:90px;
    right:15px;
  }
  img{
    margin-right:10px;
  }
}
