.chat__wrapper {
  width: 48.17%;
  position: relative;
  height: 90vh;
  margin: 0 auto;
  @media(max-width:1500px){
    width:64.17%;
  }
  @media(max-width:1300px){
    width:64.17%;
  }
  @media(max-width:1200px){
    width:84.17%;
  }
  @media(max-width:1024px){
    width:98.17%;
  }
  @media(max-width:600px){
    width:98%;
  }
  .chat__input {
    position: relative;
    margin-top: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    input {
      width: 100%;
      background: #ffffff;
      border: 1px solid #e5e5e5;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      height: 48px;
      padding-left:12px;
      color:var(--grey);
      font-family:"Inter", sans-serif;
      &:focus-visible{
        outline:none;
      }
    }
    .conversation__submit {
      position: absolute;
      right: 13px;
      background-color: #fff;
      border: none;
      width: fit-content;
      cursor: pointer;
    }
    .conversation__credits {
      width: fit-content;
      position: absolute;
      right: 39px;
      top: 12px;
      font-family: "Inter", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: var(--blue);
    }
  }
  .chat__history {
    height: 80vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
    padding-right: 2%;
    .chat__item {
      display: flex;
      img {
        width: 30px;
        height: 30px;
        margin-right: 26px;
        object-fit: cover;
      }
    }
  }
  .chat__buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
    button {
      width: fit-content;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px;
      color: var(--black);
      background: transparent;
      border: 1px solid #e5e5e5;
      border-radius: 4px;
    }
    .cancel-chat {
      margin-left: 8px;
    }
  }
}

//first access instruction
.instruction__wrapper{
  height:85vh;
  width:100%;
  display:flex;
  align-items:center;
  justify-content: center;
  @media(max-width:1500px){
    height:83vh;
  }
  @media(max-width:1024px){
    height:80vh;
  }
  @media(max-width:850px){
    height:78vh;
  }
  @media(max-width:600px){
    height:auto;
  }
  .instruction__container{
    height:auto;
    width:100%;
    @media(max-width:1024px){
      width:100%;
    }
    h1{
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color:var(--black);
      margin-bottom:60px;
      text-align: center;
      @media(max-width:600px){
        margin-bottom:45px;
      }
    }
    .instruction__row{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 14px;  
      @media(max-width:600px){
        grid-template-columns: repeat(1, 1fr);
      }    
      .instruction_col{
        display:flex;
        flex-direction:column;
        img{
          margin:0 auto;
          margin-bottom:16px;
          height:22px;
          width:22px;
        }
        h3{
          font-style: normal;
          font-weight: 500;
          font-size: 17px;
          line-height: 20px;
          text-align: center;
          color:var(--black);
          margin-bottom:21px;
        }
        .instruction__box{
          height:63px;
          background: #F7F7F8;
          border-radius: 5px;
          margin-bottom:16px;
          display:flex;
          align-items:center;
          justify-content: center;
          p{
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 20px;
            text-align:center;  
            color:var(--black);
            margin-bottom:0;
            width:95%;
            @media(max-width:600px){
              width:75%;
            }
          }
        }
      }
    }
  }
}