html {
  scroll-padding-top: 40px;
}
.ReactCollapse--collapse {
  transition: height 500ms;
  border: 0px solid #1f1f1f;
  border-radius: 10px;
}
.wizard_sizer_container{
  width:100%;
  text-align: center;
}
.wizard_sizer{
  display: inline-block;
  height: 480px;
  width: 861px;
  border: 1px solid #1f1f1f;
}
.wiz_header{
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 45px;
  overflow-x:auto;
  // gap: 40px;
  width: 861px;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 0, 0, 0.40);
  color: white;
  padding: 10px;
  grid-auto-flow: row;
  .wiz_stati_flags .workflow_wizard__sources__checkwrapper {
    grid-auto-flow: column;
  }
}

.workflow_wizard__sources__checkwrapper {
  display: grid;
  grid-template-rows: auto 1fr;
  row-gap: 1em;
  .react-switch-label {
    justify-self: end;
  }

  .workflow_wizard__sources__checkwrapper_label, input {
    justify-self: start;
    align-self: start;
  }
  
  input {
    background-color: rgb(71, 71, 71);
  }

  .workflow_wizard__sources__checkwrapper_inner {
    display: inline-block;
    vertical-align: middle;
  }
}


.dashboard__container {
  height: auto;
  padding: 28px 53px;
  scroll-padding-top: 30px;
  background: #f7f7f7;
  @media (max-width: 850px) {
    padding-top: 98px;
    height: 100vh;
    overflow-y: scroll;
  }
  @media (max-width: 600px) {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 94px;
  }
  h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: var(--black);
    margin-bottom: 30px;
    @media (max-width: 600px) {
      margin-bottom: 0;
    }
  }
  p {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 27px;
    color: var(--light-grey);
    margin-bottom: 35px;
  }



  .workflow__wizard__cards__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 28px;
    row-gap: 45px;
    overflow-x:auto;
    height:340px;
  }

    .workflow__wizard__sources__wrapper {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      overflow-x: auto;
      overflow-y: scroll;
      width: 50%;
      height: 340px;
      margin: auto;
      padding-right: 10px;

      .workflow_wizard__sources__mailwrapper {
        height: 70px;
        display: inline-block;
        .workflow_wizard__sources__mailwrapper_label {
          float: left;
          height:100%;
          vertical-align: middle;
        }

        .workflow_wizard__sources__mailwrapper_copyicon {
          float: right;
          height:100%;
          align-items: center;
        }
      }


      .workflow_wizard__sources__scrapewrapper {
        height: 150px;
        display: inline-block;

        .workflow_wizard__sources__scrapewrapper_label {
          float: left;
          display: inline-flex;
          height:100%;
          font-size: 20px;
          align-items: center;
        }
      }

    }
  .workflow__wizard__name__entry__wrapper{
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 282px;
    .workflow_wizard__name__checkwrapper {
      display: flex;
      width:60%;
      flex-direction: column;
      margin: auto;
      input, textarea {
        border: 1px solid rgba(23, 25, 31, 0.1);
        border-radius: 8px;
        padding: 10px 12px;
        margin-top: 6px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--black);
        font-family: "Inter", sans-serif;
      }

      input[type=text]:focus, textarea:focus {
        box-shadow: 0px 1px 1px #f2dede inset, 0px 0px 8px #dff0d8;
        outline: none;
      }
    }
  }

  .workflow__wizard__htmleditor__entry__wrapper{


    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    height: 340px;
    #rendered_html_frame{
      font-size: 20px;
      overflow: auto;
      height: 100%;
      zoom: 0.5;
      padding:10px;
      -moz-transform: scale(0.5);
      td{
        border: solid #aaa 1px;
        padding:5px;
      }
      ul{
        list-style-type: none;
      }
      p, span, div{
        font-size: 21px;
      }
    }



    .workflow_wizard__htmleditor__checkwrapper {
      display: flex;
      width:100%;
      height:100%;
      flex-direction: column;
      margin: auto;
      input, textarea {
        border: 1px solid rgba(23, 25, 31, 0.1);
        border-radius: 8px;
        padding: 10px 12px;
        margin-top: 6px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--black);
        font-family: "Inter", sans-serif;
      }

      input[type=text]:focus, textarea:focus {
        box-shadow: 0px 1px 1px #f2dede inset, 0px 0px 8px #dff0d8;
        outline: none;
      }
    }
  }
  .workflow__wizard__prompt__wrapper {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    overflow-y: auto;
    width: 60%;
    height: 282px;
    margin: auto;
    margin-bottom: 20px;

    .workflow__wizard__prompt__entry__wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    .workflow_wizard__prompt__checkwrapper {
      display: flex;
      width:100%;
      flex-direction: column;
      padding: 10px;
      input, textarea{
        border: 1px solid rgba(23, 25, 31, 0.1);
        border-radius: 8px;
        padding: 10px 12px;
        margin-top: 6px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--black);
        font-family: "Inter", sans-serif;
      }

      input[type=text]:focus, textarea:focus {
        box-shadow: 0px 1px 1px #f2dede inset, 0px 0px 8px #dff0d8;
        outline: none;
      }

      .workflow_wizard__prompt__checkwrapper_label {
        float: left;
        height:20px;
        font-size: 20px;
        align-items: center;
      }

      .workflow_wizard__prompt__checkwrapper_inner {
        float: right;
        display: inline-flex;
        height:100%;
        align-items: center;
      }
    }
  }


  .conversation__wrapper {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 28px;
    row-gap: 45px;
    margin-bottom: 45px;
    @media (max-width: 1250px) {
      grid-template-columns: repeat(4, 1fr);
    }
    @media (max-width: 1100px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 600px) {
      display: flex;
      grid-template-columns: unset;
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .upload__card {
      border: 2px solid rgba(23, 25, 31, 0.1);
      border-radius: 16px;
      height: 328px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        border: 2px solid #808080;
      }
      @media (max-width: 1500px) {
        height: 246px;
      }
      .upload__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          height: 25px;
          width: 25px;
          object-fit: cover;
          margin-bottom: 25px;
          cursor: pointer;
        }
        span {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: var(--grey);
        }
      }
    }
    .workflow__card {
      border: 2px solid rgba(23, 25, 31, 0.1);
      border-radius: 16px;
      height: 328px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        border: 2px solid #808080;
      }
      @media (max-width: 1500px) {
        height: 246px;
      }
      .workflowcard__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          height: 25px;
          width: 25px;
          object-fit: cover;
          cursor: pointer;
        }
        span {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: var(--grey);
        }
      }
      .upload__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          height: 25px;
          width: 25px;
          object-fit: cover;
          margin-bottom: 25px;
          cursor: pointer;
        }
        span {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: var(--grey);
        }
      }
    }

    .workflow__template__card_active{
      border: 2px solid #808080 !important;
    }
    .workflow__template__card {
      border: 2px solid rgba(23, 25, 31, 0.1);
      border-radius: 16px;
      height: 328px;
      display: inline-block;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s;
      margin-right: 20px;
      &:hover {
        border: 2px solid #808080;
      }
      @media (max-width: 1500px) {
        height: 246px;
      }

      .workflow__template__card__inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        img {
          height: 100px;
          width: 100px;
          object-fit: cover;
          cursor: pointer;
          margin-bottom: 20px;
        }
        span {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: var(--grey);
          height:110px;
        }
        h5{
          margin:20px;
          font-weight: 700;
          font-size: 23px;
          color: #101010;
          height:55px;
        }
        .workflow__template__card__top {
          width: 100%;
        }
        .sources__checkbox {
          backdrop-filter: blur(12px);
          border-radius: 4px;
          height: 18px;
          position: relative;
          right: 10px;
          top: 10px;
          width: 18px;
        }
      }
    }

    .active {
      background: linear-gradient(
        270deg,
        rgba(146, 66, 252, 0.75) 0%,
        rgba(105, 163, 242, 1) 48%,
        rgba(105, 163, 241, 1) 50%,
        rgba(66, 239, 176, 1) 100%
      );
      visibility: visible ;
      .conversation__details {
        h5 {
          color: var(--blue) !important;
        }
      }
    }
    .card {
      position: relative;
    }
    .conversation__card-wrapper {
      position: absolute;
      top: -2.5px;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 16px;
      height: 334px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      visibility: hidden;
      @media (max-width: 1500px) {
        height: 251px;
      }
      @media (max-width: 600px) {
        flex-basis: 202px;
        width: 202px;
      }
    }
    .conversation__card {
      background: #fff;
      box-shadow: 0px 30px 40px -13px rgba(18, 18, 18, 0.08);
      border-radius: 16px;
      height: 328px;
      position: relative;
      width: 97.7%;
      margin: 0 auto;
      cursor: pointer;
      @media (max-width: 1500px) {
        height: 246px;
      }
      @media (max-width: 600px) {
        flex-basis: 202px;
        width: 202px;
      }

      .conversation__detail {
        height: 25px;
        width: 25px;
        border-radius: 50%;
        position: absolute;
        background: rgba(242, 242, 242, 0.9);
        backdrop-filter: blur(2.5px);
        display: flex;
        align-items: center;
        justify-content: center;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
      .conversation__frame {
        width: 100%;
        border-radius: 16px 16px 0 0;
        height: 143px;
        object-fit: cover;
        @media (max-width: 1500px) {
          height: 106px;
        }
        @media (max-width: 600px) {
          width: 202px;
        }
      }

      .conversation__details {
        padding: 18px 20px;
        height: 140px;
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: var(--light-grey);
        }
        h5 {
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: var(--black);
          margin-top: 4px;
          word-break: break-word;
        }
        .status__message {
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: rgba(23, 25, 31, 0.4);
          margin-top: 4px;
        }
      }
      .conversation__dropdown {
        width: 120px;
        height: auto;
        background: #ffffff;
        box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.11);
        border-radius: 8px;
        padding: 8px;
        position: absolute;
        top: 37px;
        right: 10px;
        ul {
          li {
            display: flex;
            cursor: pointer;
            align-items: center;
            border-radius: 8px;
            padding: 8px;
            transition: all 0.3s;
            &:hover {
              background: rgba(0, 0, 0, 0.05);
            }
            img {
              margin-right: 7px;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: var(--black);
            }
          }
        }
      }
      .trash__checkbox {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 18px;
        height: 18px;
        backdrop-filter: blur(12px);
        border-radius: 4px;
      }
    }
  }
  .conversation-heading__flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    .upload__button {
      background: var(--black);
      border: 1px solid #17191f;
      border-radius: 8px;
      width: fit-content;
      color: #fff;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      padding: 12px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-right: 15px;
      img {
        margin-right: 8px;
      }
    }
  }
  .previous-title__mobile {
    margin-bottom: 30px;
  }
}

.loading__frame {
  background: var(--black);
  height: 143px;
  border-radius: 16px 16px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1500px) {
    height: 106px;
  }
  @media (max-width: 600px) {
    width: 202px;
  }
}

.loading__frame-error {
  background: #fdf1eb !important;
}

.pdf_icon {
  margin-top: 20px;
  margin-left: 33.77px;
}

//trash
.trash__actions {
  margin-bottom: 35px;
  position: relative;
  button {
    width: fit-content;
    background: transparent;
    padding: 4px 8px;
    border: 1px solid rgba(23, 25, 31, 0.2);
    border-radius: 8px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    img {
      margin-left: 6px;
    }
  }
  .actions__dropdown {
    width: 120px;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
    padding: 8px;
    position: absolute;
    top: 37px;
    left: 0px;
    z-index: 9999999999;
    ul {
      li {
        display: flex;
        cursor: pointer;
        align-items: center;
        border-radius: 8px;
        padding: 8px;
        transition: all 0.3s;
        &:first-child {
          margin-bottom: 11.5px;
        }
        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }
        img {
          margin-right: 7px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--black);
        }
      }
    }
  }
}

.gelmatic_test_container {
  // background: #ffffff;
  // border-radius: 16px;
  color: var(--black);
  width: 100%;
  height: 100vh;
  @media (max-width: 600px) {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
}


//detail wrapper

.overlay {
  background: rgba(23, 25, 31, 0.6);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    top: unset;
    bottom: 0;
  }
  //conversation modal
  .conversation__modal {
    background: #ffffff;
    border-radius: 16px;
    color: var(--black);
    width: 60%;
    @media (max-width: 600px) {
      width: 100%;
      position: absolute;
      bottom: 0;
    }
    .modal__header {
      padding: 20px 24px;
      border-bottom: 1px solid rgba(23, 25, 31, 0.1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      img {
        cursor: pointer;
      }
    }
    .modal__body {
      padding: 24px;
      padding-bottom: 34px;
      position: relative;
      .capital {
        text-transform: uppercase;
        span {
          text-transform: none;
        }
      }
      .modal__row {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: rgba(23, 25, 31, 0.4);
        .content__title {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: rgba(23, 25, 31, 0.4);
          margin-bottom: 0;
          border: 1px solid rgba(23, 25, 31, 0.1);
          border-radius: 8px;
          padding: 10px 12px;
          margin-top: 6px;
        }
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--black);
        }
        textarea {
          background: #ffffff;
          border: 1px solid rgba(23, 25, 31, 0.1);
          border-radius: 8px;
          padding: 10px 12px;
          margin-top: 6px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--black);
          font-family: "Inter", sans-serif;
          &:focus-visible {
            outline: none;
          }
        }
      }
      .modal__button {
        position: absolute;
        bottom: 20px;
        right: 31px;
        background: var(--black);
        width: fit-content;
        color: #fff;
        padding: 4px 8px;
        border-radius: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        border: none;
        cursor: pointer;
      }
    }
  }

  .workflow_wizard__modal {
    background: #ffffff;
    border-radius: 16px;
    color: var(--black);
    width: 60%;
    @media (max-width: 600px) {
      width: 100%;
      position: absolute;
      bottom: 0;
    }
    .modal__header {
      padding: 20px 24px;
      border-bottom: 1px solid rgba(23, 25, 31, 0.1);
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      img {
        cursor: pointer;
      }
    }
    .modal__body {
      padding: 24px;
      padding-bottom: 34px;
      position: relative;
      .capital {
        text-transform: uppercase;
        span {
          text-transform: none;
        }
      }
      .modal__row {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: rgba(23, 25, 31, 0.4);
        .content__title {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: rgba(23, 25, 31, 0.4);
          margin-bottom: 0;
          border: 1px solid rgba(23, 25, 31, 0.1);
          border-radius: 8px;
          padding: 10px 12px;
          margin-top: 6px;
        }
        span {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--black);
        }
        textarea {
          background: #ffffff;
          border: 1px solid rgba(23, 25, 31, 0.1);
          border-radius: 8px;
          padding: 10px 12px;
          margin-top: 6px;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--black);
          font-family: "Inter", sans-serif;
          &:focus-visible {
            outline: none;
          }
        }
      }
      .modal__button {
        position: absolute;
        bottom: 20px;
        right: 31px;
        background: var(--black);
        width: fit-content;
        color: #fff;
        padding: 4px 8px;
        border-radius: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        border: none;
        cursor: pointer;
      }
    }
  }
  //confirm delete modal
  .delete__modal {
    background: #ffffff;
    border-radius: 8px;
    width: 28.47%;
    @media (max-width: 1025px) {
      width: 38.47%;
    }
    @media (max-width: 850px) {
      width: 52.47%;
    }
    @media (max-width: 600px) {
      width: 90%;
    }
    .delete__modal-header {
      padding: 20px 24px;
      border-bottom: 1px solid rgba(23, 25, 31, 0.1);
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: var(--black);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .delete__modal-body {
      padding: 15px 24px;
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        color: #6b7280;
        margin-bottom: 35px;
        text-align: center;
      }
      .delete__buttons-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        button {
          width: fit-content;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          padding: 6px 16px;
          border-radius: 8px;
        }
        .cancel-button {
          color: var(--black);
          border: 1px solid rgba(0, 0, 0, 0.1);
          background: transparent;
        }
        .delete-button {
          background: #dc5a3a;
          color: #fff;
          border: 1px solid #dc5a3a;
          margin-left: 12px;
        }
        .save-button {
          background: var(--black);
          color: #fff;
          border: 1px solid var(--black);
          margin-left: 12px;
        }
      }
    }
    .reminder-body {
      p {
        text-align: left;
      }
    }
  }
}

.desktop {
  @media (max-width: 600px) {
    display: none !important;
  }
}

.mobile {
  @media (min-width: 601px) {
    display: none !important;
  }
}

.cardSwiper {
  @media (min-width: 601px) {
    display: none !important;
  }
}

//uploader

.uploader__container {
  width: 28.47%;
  background: #fff;
  border-radius: 16px;
  @media (max-width: 1024px) {
    width: 55.47%;
  }
  @media (max-width: 850px) {
    width: 78.47%;
  }
  @media (max-width: 600px) {
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  .uploader__header {
    padding: 20px 24px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: var(--black);
    border-bottom: 1px solid rgba(23, 25, 31, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    img {
      cursor: pointer;
    }
  }
  .uploader__body {
    padding: 24px;
    form {
      input {
        border: 1px solid rgba(23, 25, 31, 0.1);
        border-radius: 8px;
        color: var(--grey);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        width: 100%;
      }
      select {
        border: none;
        border-radius: 8px;
        width: 100%;
        padding: 10px 12px;
        height: 40px;
        color: var(--grey);
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        -webkit-appearance: none;
        -moz-appearance: none;
        position: relative;
        z-index: 1;
        background: transparent;
        &:focus-visible {
          outline: none;
        }
      }
      label {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 6px;
      }
    }
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      margin-bottom: 0;
      color: #888888;
      margin-top: 14px;
    }
  }
}

.select__wrapper {
  border: 1px solid rgba(23, 25, 31, 0.1);
  border-radius: 8px;
  margin-bottom: 15px;
  position: relative;
  img {
    position: absolute;
    top: 13.5px;
    right: 13.5px;
    z-index: 0;
  }
}

.uppy-Dashboard--singleFile .uppy-Dashboard-Item-preview {
  display: none;
}

.uppy-Dashboard-inner {
  background: #fff !important;
  border: none !important;
}
.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  background: var(--black) !important;
}
.uppy-Informer {
  p {
    color: #fff !important;
  }
}

.uppy-Dashboard-Item-errorMessageBtn{
  display:none!important;
}

//Content tab
.content__tab {
  width: 82.6%;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 10px 40px -10px rgba(18, 18, 18, 0.1);
  border-radius: 16px 16px 0px 0px;
  height: 522px;
  left: 16%;
  display: flex;
  position: fixed;
  bottom: 0;
  border: 1px solid rgba(23, 25, 31, 0.1);
  @media (max-width: 1440px) {
    left: 17%;
  }
  @media (max-width: 1024px) {
    width: 100%;
    left: 0;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    height: 670px;
  }
  .tab__body {
    flex-basis: 92.07%;
    .tab-body__container {
      width: 71.04%;
      margin: 0 auto;
      padding-top: 25px;
      @media (max-width: 600px) {
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
        padding-bottom: 35px;
      }
      h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(--black);
        margin-bottom: 34px;
        width: 112%;
        word-break: break-all;
      }
      .recap__title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0px;
        transition: all 0.3s;
      }
      .tab__content {
        height: 371px;
        font-style: normal;
        padding-left: 12px;
        background: #ffffff;
        border: 1px solid rgba(23, 25, 31, 0.1);
        border-radius: 4px;
        //overflow-y: scroll;
        word-break: break-word;
        @media (max-width: 600px) {
          height: 455px;
        }
        .recap__title-flex {
          display: flex;
          align-items: center;
          width:100%;
          margin-top:20px;
          @media (max-width: 600px) {
            display: none;
          }
          h3 {
            margin-bottom: 0;
          }
          p {
            margin-bottom: 0;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: var(--blue);
          }
          .recap__credits {
            margin-right: 12px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
            color: var(--blue);
            img {
              width: 14px;
              height: 14px;
              object-fit: cover;
              margin-right: 4px;
            }
          }
          button {
            width: fit-content;
            background: rgba(105, 163, 242, 0.1);
            border-radius: 18px;
            padding: 0 6px;
            color: var(--blue);
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            border: none;
            cursor: pointer;
          }
        }
        p {
          font-weight: 500;
          font-size: 15px;
          line-height: 27px;
          color: var(--light-grey);
        }
        textarea {
          font-family: "inter", sans-serif;
          height: 355px;
          width: 100%;
          border: none;
          font-weight: 500;
          font-size: 15px;
          line-height: 27px;
          color: var(--black);
          //overflow-y: scroll;
          resize: none;
          &:focus-visible {
            outline: none;
          }
          &:disabled {
            color: #808080;
            background: #fff;
          }
        }
      }
      .tab__content-conversation {
        height: 371px;
        font-style: normal;
        overflow-y: scroll;
        padding: 10px 40px 10px 12px;
        background: #ffffff;
        border-radius: 4px;
        display: flex;
        flex-direction: column-reverse;
        border: 1px solid rgba(23, 25, 31, 0.1);
        border-bottom: none;
        margin-bottom: 16px;
        @media (max-width: 600px) {
          height: 455px;
        }
        .conversation-items{
          margin-bottom:15px;
          .conversation__item {
            display: flex;
            img {
              height: 30px;
              width: 30px;
              object-fit: cover;
              margin-right: 26px;
            }
          }
        }
        
      }
      .conversation__form {
        position: relative;
        .conversation__clear {
          position: absolute;
          bottom: 25px;
          left: 48%;
          border: 1px solid #e5e5e5;
          border-radius: 4px;
          background-color: #fff;
          width:38px;
          height:38px;
          display:flex;
          align-items:center;
          justify-content: center;
          cursor:pointer;
        }
        .conversation__input {
          background: #ffffff;
          border: 1px solid #e5e5e5;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
          border-radius: 4px;
          width: 100%;
          padding-top: 15px;
          padding-bottom: 15px;
          padding-left: 10px;
          padding-right: 60px;
          resize: none;
          position: absolute;
          bottom: -46px;
          color: var(--grey);
          font-family: "Inter", sans-serif;
          max-height: 200px;
          font-size: 15px;
          line-height: 18px;
          &:focus-visible {
            outline: none;
          }
          &:disabled {
            background-color: #eee;
            box-shadow: none;
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
        .conversation__submit {
          position: absolute;
          right: 13px;
          top: 4px;
          background-color: transparent;
          border: none;
          width: fit-content;
          height: 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          &:disabled {
            cursor: not-allowed;
          }
        }
        .conversation__credits {
          width: fit-content;
          position: absolute;
          right: 39px;
          top: 12px;
          font-family: "Inter", sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: var(--blue);
        }
      }
      .flex__end {
        display: flex;
        justify-content: flex-end;
        margin-top: 15px;
      }
    }
  }
  .tab__menu {
    flex-basis: 7.93%;
    border-left: 1px solid rgba(23, 25, 31, 0.1);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 600px) {
      display: none;
    }
    .close-tab {
      position: absolute;
      top: 20px;
      right: 45%;
      cursor: pointer;
    }
    .tab-menu__flex {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      //margin-right: 26px;
      height: 95%;
      .tab-menu__buttons {
        margin-top: 80px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .tab-button__wrapper {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          transition: all 0.3s;
          &:hover .tab-button__tag {
            display: block;
          }
          .tab-button__tag {
            background: #17191f;
            border-radius: 4px;
            width: fit-content;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 18px;
            color: #fff;
            position: absolute;
            padding: 10px 8px;
            left: -60%;
            width: 143px;
            display: none;
            transition: all 0.3s;
            text-align: center;
            @media (max-width: 2160px) {
              left: -120px;
            }
            // @media (max-width: 1820px) {
            //   left: -110%;
            // }
            // @media (max-width: 1590px) {
            //   left: -135%;
            // }
            @media (max-width: 1550px) {
              left: -135px;
            }
            // @media (max-width: 1190px) {
            //   left: -213%;
            // }
            // @media (max-width: 960px) {
            //   left: -206%;
            // }
            // @media (max-width: 850px) {
            //   left: -213%;
            // }
            @media (max-width: 1025px) {
              left: -145px;
            }
            @media (max-width: 700px) {
              left: -160px;
            }
          }
          .tab__button {
            height: 40px;
            width: 40px;
            border: 1px solid rgba(23, 25, 31, 0.1);
            backdrop-filter: blur(22px);
            border-radius: 7px;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
      .tab__share {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;
        &:hover .tab-button__tag-share {
          display: block;
        }
        span {
          color: var(--grey);
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
        }
        .share__button {
          background: var(--black);
          border: 1px solid var(--black);
          border-radius: 8px;
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          margin-top: 4px;
        }
        .tab-button__tag-share {
          background: #17191f;
          border-radius: 4px;
          width: fit-content;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          color: #fff;
          position: absolute;
          padding: 10px 8px;
          top: 5px;
          left: -88%;
          width: 143px;
          display: none;
          transition: all 0.3s;
          @media (max-width: 2160px) {
            left: -160px;
          }
        }
      }
    }
  }
  .tab__menu-mobile {
    display: flex;
    padding-top: 20px;
    padding-left: 15px;
    position: relative;
    @media (min-width: 601px) {
      display: none;
    }
    .close-tab {
      position: absolute;
      top: 20px;
      right: 15px;
    }
    .tab__button {
      @media (max-width: 600px) {
        border: 1px solid rgba(23, 25, 31, 0.1);
        backdrop-filter: blur(22px);
        border-radius: 7px;
        height: 45px;
        width: 45px;
        margin-right: 14.91px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .share__button {
      background: var(--black);
      border: 1px solid var(--black);
      border-radius: 8px;
      height: 45px;
      width: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

//input tags
.rti--container {
  margin-top: 5px !important;
  &:focus-visible {
    outline: none !important;
    border: 1px solid rgba(23, 25, 31, 0.1) !important;
  }
  &:focus-within {
    outline: none !important;
    border: 1px solid rgba(23, 25, 31, 0.1) !important;
    box-shadow: none !important;
  }
}

.recap__title-flex {
  display: flex;
  align-items: center;
  width:100%;
  margin-top:20px;
  @media (max-width: 600px) {
    display: none;
  }
  h3 {
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--blue);
  }
  
}
// .title__tooltip{
//   display:none;
//   position:absolute;
//   top:0;
//   left:0;
//   height:auto;
//   width:100%;
//   word-break: break-all;
// }

// .recap__title:hover .title__tooltip{
//   display:block!important;
// }
